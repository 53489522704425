import { Close, Edit } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { EstimateModal, InsuranceDetailsModal } from "../EditReport";
import EditKeyAndAdditional from "./EditKeyandAdditional";
import { DentsModal } from "./DentsModal";
import { PortalContext } from "../../../../context/carPortalContext";
import { isEqual } from "lodash";

// const calculateAverageTyreLife = (tyreLifeData) => {
//   const tyreKeys = Object.keys(tyreLifeData);
//   const validLifeValues = tyreKeys.filter(
//     (key) => key !== "tyreLife" && tyreLifeData[key].endsWith("%")
//   );
//   const totalLife = validLifeValues.reduce((acc, key) => {
//     const value = parseInt(tyreLifeData[key].replace("%", ""), 10);
//     return acc + (isNaN(value) ? 0 : value);
//   }, 0);
//   const averageLife =
//     validLifeValues.length > 0 ? totalLife / validLifeValues.length : 0;
//   return isNaN(averageLife) ? "-" : `${averageLife.toFixed(0)}%`;
// };

const KeyInformation = ({
  initialKeyInfo,
  insuranceData: initialInsuranceData,
  dentsData: initialDentsData,
  tyreLifeData: initialTyreLifeData,
  majorRepairData: initialMajorRepairData,
  minorRepairData: initialMinorRepairData,
  isPublished,
}) => {
  const {
    keyInfo,
    setKeyInfo,
    updateKeyInfo,
    insuranceData,
    updateInsuranceData,
    dentsData,
    setDentsData,
    tyreLifeData,
    updateTyreLifeData,
    majorRepairs,
    setMajorRepairs,
    minorRepairs,
    setMinorRepairs,
    removeRepair,
  } = useContext(PortalContext);
  const [isEditing, setIsEditing] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [isTyreLifeModalOpen, setIsTyreLifeModalOpen] = useState(false);
  const [openInsuranceModal, setOpenInsuranceModal] = useState(false);
  const [isDentsModalOpen, setIsDentsModalOpen] = useState(false);
  console.log("major", selectedEstimate, majorRepairs, "miore", minorRepairs);
  useEffect(() => {
    if (initialKeyInfo) {
      setKeyInfo((prev) => ({ ...prev, ...initialKeyInfo }));
    }
    if (initialInsuranceData) {
      updateInsuranceData(initialInsuranceData);
    }
    if (initialDentsData) {
      setDentsData((prev) => ({ ...prev, ...initialDentsData }));
    }
    if (initialTyreLifeData) {
      updateTyreLifeData((prev) => ({ ...prev, ...initialTyreLifeData }));
    }
    if (initialMajorRepairData) {
      console.log("Initial Major Repair Data:", initialMajorRepairData); // Debug log
      setMajorRepairs([...initialMajorRepairData]);
    }
    if (initialMinorRepairData) {
      console.log("Initial Minor Repair Data:", initialMinorRepairData); // Debug log
      setMinorRepairs([...initialMinorRepairData]);
    }
  }, [
    initialKeyInfo,
    initialInsuranceData,
    initialDentsData,
    initialTyreLifeData,
    initialMajorRepairData,
    initialMinorRepairData,
  ]);

  const averageTyreLife = useMemo(() => {
    const tyreKeys = Object.keys(tyreLifeData);
    const validLifeValues = tyreKeys.filter(
      (key) => key !== "tyreLife" && tyreLifeData[key].endsWith("%")
    );
    const totalLife = validLifeValues.reduce((acc, key) => {
      const value = parseInt(tyreLifeData[key].replace("%", ""), 10);
      return acc + (isNaN(value) ? 0 : value);
    }, 0);
    return validLifeValues.length > 0
      ? (totalLife / validLifeValues.length).toFixed(0) + "%"
      : "-";
  }, [tyreLifeData]);

  const calculateInsuranceExpiry = (expiryDate) => {
    const today = new Date();
    const expirationDate = new Date(expiryDate);
    const diffTime = expirationDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays < 0
      ? `Expired ${Math.abs(diffDays)} days ago`
      : `Expires in ${diffDays} days`;
  };

  const insuranceStatus = useMemo(() => {
    const today = new Date();
    const expiry = new Date(insuranceData?.expiryDate);
    return expiry >= today ? "Valid" : "Expired";
  }, [insuranceData?.expiryDate]);

  const insuranceDetails = {
    insurer: insuranceData?.insurer || "Not Available",
    number: insuranceData?.policyNumber || "Not Available",
    expiryDate: insuranceData?.expiryDate || "Not Available",
    expiresIn: insuranceData?.expiryDate
      ? calculateInsuranceExpiry(insuranceData?.expiryDate)
      : "Not Available",
    status: insuranceData?.expiryDate
      ? new Date(insuranceData?.expiryDate) >= new Date()
        ? "Valid"
        : "Expired"
      : "Not Available",
  };

  const handleOpenTyreLifeModal = () => {
    setIsTyreLifeModalOpen(true);
  };

  const handleCloseTyreLifeModal = () => {
    setIsTyreLifeModalOpen(false);
  };

  const handleOpenDentsModal = () => {
    setIsDentsModalOpen(true);
  };

  const handleCloseDentsModal = () => {
    setIsDentsModalOpen(false);
  };

  const handleOpenInsuranceModal = () => {
    setOpenInsuranceModal(true);
  };

  const handleCloseInsuranceModal = () => {
    setOpenInsuranceModal(false);
  };

  const handleOpenModal = (repairType) => {
    setSelectedEstimate(
      repairType === "Major Repairs" ? majorRepairs : minorRepairs
    );
    setModalTitle(`${repairType} Estimate`);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const images = [
    {
      original: "https://via.placeholder.com/600x300",
      description: "Front-Left Tyre",
    },
    {
      original: "https://via.placeholder.com/600x300",
      description: "Front-Right Tyre",
    },
    {
      original: "https://via.placeholder.com/600x300",
      description: "Rear-Left Tyre",
    },
    {
      original: "https://via.placeholder.com/600x300",
      description: "Rear-Right Tyre",
    },
  ];

  const handleOpenEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    console.log("Key Info updated in KeyInformation:", keyInfo);
  }, [keyInfo]);

  const handleCloseEdit = () => {
    setIsEditing(false);
  };
  const calculateTotal = (repairs) => {
    if (!Array.isArray(repairs)) {
      console.error("Invalid repairs data:", repairs);
      return 0;
    }

    return repairs.reduce((acc, repair) => {
      const costString = repair?.price || ""; // Fallback to an empty string if cost is undefined
      const numericValue = parseFloat(costString.replace(/[^\d.-]/g, "")); // Extract numeric value
      return acc + (isNaN(numericValue) ? 0 : numericValue); // Add only valid numbers
    }, 0);
  };
  const totalMajorRepairs = useMemo(
    () => calculateTotal(majorRepairs),
    [majorRepairs]
  );
  const totalMinorRepairs = useMemo(
    () => calculateTotal(minorRepairs),
    [minorRepairs]
  );

  const hasDents = keyInfo?.dentsOnBody?.dents === "YES";
  const hasDentDetails = Object.entries(dentsData).some(
    ([_, details]) => details.Dents || details.Scratches
  );

  const hasTyreLifeData =
    tyreLifeData &&
    tyreLifeData["Front-Left"] &&
    tyreLifeData["Front-Right"] &&
    tyreLifeData["Rear-Left"] &&
    tyreLifeData["Rear-Right"];

  return (
    <div className="key-information-container">
      <h3 className="key-information__title">Key Information</h3>
      <div className="key-information__grid">
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Kms Driven</span>
          <span className="vehicle-info-value highlighted">
            {keyInfo?.kmsDriven ? keyInfo?.kmsDriven : "-"}
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Major Accident</span>
          <span className="key-info-value">
            {keyInfo?.majorAccident ? keyInfo?.majorAccident : "-"}
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Flood Damage</span>
          <span className="key-info-value">
            {keyInfo?.floodDamage ? keyInfo?.floodDamage : "-"}
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Engine Condition</span>
          <span className="key-info-value highlighted-yellow">
            {keyInfo?.engineCondition ? keyInfo?.engineCondition : "-"}
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Transmission</span>
          <span className="key-info-value highlighted-yellow">
            {keyInfo?.transmission ? keyInfo?.transmission : "-"}
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Exterior Condition</span>
          <span className="key-info-value highlighted-red">
            {keyInfo?.exteriorCondition ? keyInfo?.exteriorCondition : "-"}
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Interior Condition</span>
          <span className="key-info-value highlighted-red">
            {keyInfo?.interiorCondition ? keyInfo?.interiorCondition : "-"}
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Dents & Scratches</span>
          <span className="key-info-value highlighted-red">
            {hasDents || hasDentDetails ? "YES" : "-"}{" "}
            {hasDents || hasDentDetails ? (
              <span
                style={{
                  fontSize: "12px",
                  color: "#003cc5",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handleOpenDentsModal}
              >
                Details
              </span>
            ) : null}
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Avg Tyre Life</span>
          <span
            className="key-info-value"
            style={{ display: "flex", gap: "5px", alignItems: "baseline" }}
          >
            {averageTyreLife || "-"}
            {hasTyreLifeData && (
              <span
                style={{
                  fontSize: "12px",
                  color: "#003cc5",
                  textDecoration: "underline",
                  alignItems: "baseline",
                }}
                onClick={handleOpenTyreLifeModal}
              >
                Details
              </span>
            )}
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Insurance</span>
          <span
            className="key-info-value highlighted-red"
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "baseline",
              color:
                insuranceDetails?.status === "Valid" ? "#388e3c" : "#d32f2f",
            }}
          >
            {insuranceDetails.status}
            <span
              style={{
                fontSize: "12px",
                color: "#003cc5",
                textDecoration: "underline",
                alignItems: "baseline",
              }}
              onClick={handleOpenInsuranceModal}
            >
              {/* <TireRepairOutlined
                    fontSize="small"
                    sx={{ marginTop: "4px" }}
                  /> */}
              Details
            </span>
          </span>
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Major Repair</span>
          <span
            className="key-info-value"
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "baseline",
              color: "#000",
              fontWeight: "600",
            }}
          >
            INR {totalMajorRepairs}
            <span
              style={{
                fontSize: "12px",
                color: "#003cc5",
                textDecoration: "underline",
              }}
              onClick={() => handleOpenModal("Major Repairs")}
            >
              {/* <DescriptionOutlined
                    fontSize="small"
                    sx={{ marginTop: "4px" }}
                  /> */}
              Estimate
            </span>
          </span>
          {/* Implement Major and Minor Functionality here and add */}
        </div>
        <div className="key-info-item">
          <span className="key-info-label">Minor Repair</span>
          <span
            className="key-info-value"
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "baseline",
              fontWeight: "600",
            }}
          >
            INR {totalMinorRepairs}
            <span
              style={{
                fontSize: "12px",
                color: "#003cc5",
                textDecoration: "underline",
              }}
              onClick={() => handleOpenModal("Minor Repairs")}
            >
              {/* <DescriptionOutlined
                    fontSize="small"
                    sx={{ marginTop: "4px" }}
                  /> */}
              Estimate
            </span>
          </span>
        </div>
      </div>
      {!isPublished && (
        <IconButton
          onClick={handleOpenEdit}
          style={{ position: "absolute", top: 16, right: 16 }}
        >
          <Edit />
        </IconButton>
      )}
      <Dialog
        open={isEditing}
        onClose={handleCloseEdit}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: "12px", // Add rounded corners
          },
        }}
      >
        <DialogTitle>Edit Key and Additional Information</DialogTitle>
        <DialogContent>
          <EditKeyAndAdditional
            onClose={handleCloseEdit}
            keyInfo={keyInfo}
            updateParentState={updateKeyInfo}
          />
        </DialogContent>
      </Dialog>
      <EstimateModal
        open={openModal}
        handleClose={handleCloseModal}
        estimates={selectedEstimate}
        title={modalTitle}
        handleDelete={removeRepair}
        isPublished={isPublished}
      />
      <InsuranceDetailsModal
        open={openInsuranceModal}
        onClose={handleCloseInsuranceModal}
        insuranceDetails={insuranceDetails}
      />
      <DentsModal
        open={isDentsModalOpen}
        onClose={handleCloseDentsModal}
        images={images}
        dentsData={dentsData ?? {}}
      />
      <Dialog
        open={isTyreLifeModalOpen}
        onClose={handleCloseTyreLifeModal}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle sx={{ color: "#003cc5" }}>
          Tyre Life Details
          <IconButton
            aria-label="close"
            onClick={handleCloseTyreLifeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: "30px" }}>
          <Grid container spacing={2}>
            {tyreLifeData ? (
              Object.entries(tyreLifeData).map(([tyre, lifePercentage]) => {
                if (tyre !== "tyreLife") {
                  return (
                    <Grid item xs={6} key={tyre}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500,
                          color: "#4b5563",
                          fontSize: "0.9rem",
                          fontFamily: "Manrope, sans-serif",
                        }}
                      >
                        {tyre.replace(/-/g, " ")}:
                      </Typography>
                      <Box display="flex" alignItems="end">
                        <Typography
                          variant="body1"
                          sx={{
                            paddingTop: "4px !important",
                            color: "#000",
                            fontSize: "15px",
                            fontWeight: 700,
                            fontFamily: "Manrope, sans-serif",
                          }}
                        >
                          {lifePercentage}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                }
              })
            ) : (
              <Grid item xs={12}>
                <Typography>No tyre life data available.</Typography>
              </Grid>
            )}
          </Grid>
          {/* <Box sx={{ marginTop: "16px" }}>
              <ImageGallery
                items={images.filter((image) => image && image.original)}
                showThumbnails={false}
                showPlayButton={false}
                showFullscreenButton={false}
                thumbnailPosition="bottom"
                showNav={true}
                slideInterval={2000}
                renderItem={(item, index) => (
                  <Box position="relative">
                    <img
                      src={item.original}
                      alt={`Tyre ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        position: "absolute",
                        top: "8px",
                        right: "16px",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        color: "#fff",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      {item.description || `Tyre ${index + 1}`}
                    </Typography>
                  </Box>
                )}
              />
            </Box> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default KeyInformation;
