import { useEffect, useState } from "react";
import { Button, TextField, Box, MenuItem } from "@mui/material";

const EditSummary = ({ onClose, data, onDataChange }) => {
  const [marketValue, setMarketValue] = useState(data.marketValue);
  const [recommended, setRecommended] = useState(data.recommended);
  const [overallRating, setOverallRating] = useState(data.overallRating);
  const [marketValueError, setMarketValueError] = useState(false);
  const [overallRatingError, setOverallRatingError] = useState(false);

  useEffect(() => {
    console.log("Updating EditSummary state with new props:", data);
    setMarketValue(data.marketValue);
    setRecommended(data.recommended);
    setOverallRating(data.overallRating);
  }, [data]);

  const handleSave = () => {
    // Logic to save the changes
    const isMarketValueValid = isValidMarketValue(marketValue);
    const isOverallRatingValid =
      overallRating !== "" &&
      Number(overallRating) >= 1 &&
      Number(overallRating) <= 10;
      setMarketValueError(!isMarketValueValid);
      setOverallRatingError(!isOverallRatingValid);
      if (!isMarketValueValid || !isOverallRatingValid) {
        console.error("Validation failed. Correct the errors before saving.");
        return;
      }
    onDataChange({
      marketValue,
      recommended,
      overallRating
    });
    onClose();
  };

  const handleRatingChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 1 && Number(value) <= 10)) {
      setOverallRating(value === "" ? "" : Number(value));
    }
  };

  const handleMarketValueChange = (e) => {
    const value = e.target.value;
    setMarketValue(value);
    setMarketValueError(!isValidMarketValue(value));
  };

  const isValidMarketValue = (value) => {
    // Regex to match the format with or without space after "Rs" and ensure consistent formatting
    const regex = /^Rs\s?\d{1,3}(,\d{2,3}){0,2}\/- - Rs\s?\d{1,3}(,\d{2,3}){0,2}\/-$/;
    
    if (!regex.test(value)) {
      return false; // Invalid if it doesn't match the regex
    }
  
    // Normalize values by ensuring proper spacing and extracting numeric parts
    const [start, end] = value
      .replace(/Rs\s?/g, "") // Remove "Rs " or "Rs"
      .replace(/\/-/g, "")  // Remove "/-"
      .split(" - ")         // Split the range
      .map((v) => parseInt(v.replace(/,/g, ""), 10)); // Remove commas and convert to numbers
  
    return start <= end; // Ensure the range is valid
  };

  return (
    <Box display="flex" flexDirection="column" gap="16px" sx={{paddingTop: "4px"}}>
      <TextField
        label="Market Value"
        value={marketValue}
        onChange={handleMarketValueChange}
        fullWidth
        error={marketValueError}
        helperText={marketValueError ? "Enter value in format: Rs X,XX,XXX/- - Rs X,XX,XXX/-" : ""}
      />
      <TextField
        select
        label="Recommended"
        value={recommended}
        onChange={(e) => setRecommended(e.target.value)}
        fullWidth
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </TextField>
      <TextField
        label="Overall Rating"
        type="number"
        value={overallRating}
        error={overallRatingError}
        onChange={handleRatingChange}
        fullWidth
        inputProps={{ min: 1, max: 10 }}
      />
      <Box display="flex" justifyContent="flex-end" gap="8px">
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditSummary;
