import { Close, Commit, Edit } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { PortalContext } from "../../../../context/carPortalContext";

const VehicleInformation = ({vechicleInfo}) => {
    return (
      <div className="key-information-container" style={{ margin: "0px" }}>
        <h3 className="vehicle-information__title">Vehicle Information</h3>
        <div className="vehicle-information__grid">
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Make:</span>
            <span className="vehicle-info-value">{vechicleInfo?.make ?? '-'}</span>
          </div>
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Owners:</span>
            <span className="vehicle-info-value highlighted" style={{color: vechicleInfo?.owners === '1' ? '#1a1a1a' : '#d32f2f'}}>{vechicleInfo?.owners ?? '-'}</span>
          </div>
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Model:</span>
            <span className="vehicle-info-value">{vechicleInfo?.model ?? '-'}</span>
          </div>
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Transmission:</span>
            <span className="vehicle-info-value">{(vechicleInfo?.transmission === 'AUTO' ? 'AUTOMATIC' : 'MANUAL') ?? '-'}</span>
          </div>
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Year:</span>
            <span className="vehicle-info-value">{vechicleInfo?.mfgDate ?? '-'}</span>
          </div>
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Reg No:</span>
            <span className="vehicle-info-value highlighted">{(vechicleInfo?.regNo ?? '-').toUpperCase()}</span>
          </div>
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Mfg. Date:</span>
            <span className="vehicle-info-value">{vechicleInfo?.mfgDate ?? '-'}</span>
          </div>
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Fuel:</span>
            <span className="vehicle-info-value">{vechicleInfo?.fuel ?? '-'}</span>
          </div>
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Colour:</span>
            <span className="vehicle-info-value">{vechicleInfo?.colour ?? '-'}</span>
          </div>
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Reg. Date:</span>
            <span className="vehicle-info-value">{vechicleInfo?.regDate ?? '-'}</span>
          </div>
        </div>
      </div>
    );
  };
  
  export const VehicleInformationModal = ({ open, handleClose }) => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: "12px", // Set border radius here
            overflow: "hidden", // Helps ensure the rounded corners are visible
          },
        }}
      >
        <DialogTitle
          className="vehicle-information__title"
          sx={{ fontFamily: "Manrope, sans-serif", color: "#003cc5" }}
        >
          Vehicle Information
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0, // Remove default padding
          }}
        >
          <VehicleInformation />
        </DialogContent>
      </Dialog>
    );
  };

const InspectionSummary = ({initialSummary, isPublished}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [openTextDialog, setOpenTextDialog] = useState(false);
    const [editedSummary, setEditedSummary] = useState("");
    const { inspectionSummary, updateInspectionSummary, lastSaved } = useContext(PortalContext);

    useEffect(() => {
      // This will initialize the summary from the API response if not already set
      if (!inspectionSummary && (initialSummary?.summary || initialSummary?.aiInsights?.summary)) {
          updateInspectionSummary(initialSummary.summary || initialSummary.aiInsights.summary);
      }
  }, [initialSummary, inspectionSummary, updateInspectionSummary]);

    const previewLimit = 200;
    const shouldShowViewMore = inspectionSummary.length > previewLimit;
  
    const handleOpenTextDialog = () => {
      setEditedSummary(inspectionSummary);
      setOpenTextDialog(true);
    };
  
    const handleCloseTextDialog = () => {
      setOpenTextDialog(false);
    };
  
    const handleSave = () => {
      updateInspectionSummary(editedSummary);
      setOpenTextDialog(false);
  };
  
    return (
      <div className="inspection-summary-container">
        {/* Inspection Summary Section */}
        <div className="inspection-summary">
          <h3 className="inspection-summary__title">
            <Commit color="disabled" /> Inspection Summary{" "}
            <Commit color="disabled" />
          </h3>
          <p className="inspection-summary__checkpoints">
            200+ checkpoints inspected
          </p>
        </div>
        <VehicleInformation vechicleInfo={initialSummary?.vehicleInformation}/>
        {/* Interpretations Section */}
        <div className="interpretations">
          <h4 className="interpretations__title">Assessment</h4>
          {!isPublished && <IconButton
            onClick={handleOpenTextDialog}
            style={{ position: "absolute", top: 10, right: 16 }}
          >
            <Edit />
          </IconButton>}
          <p className="interpretations__text">
            {isExpanded || !shouldShowViewMore
              ? inspectionSummary
              : `${inspectionSummary.slice(0, previewLimit)}...`}
            {shouldShowViewMore && (
              <span
                onClick={() => setIsExpanded(!isExpanded)}
                style={{ color: "#003cc5", cursor: "pointer" }}
              >
                &nbsp;{isExpanded ? "Show Less" : "View More"}
              </span>
            )}
          </p>
        </div>
  
        {/* Edit Dialog */}
        <Dialog
          open={openTextDialog}
          onClose={handleCloseTextDialog}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              borderRadius: "16px",
            },
          }}
        >
          <DialogTitle sx={{ color: "#003cc5" }}>
            Edit Assessment{" "}
            <span style={{ fontSize: "12px", color: "#4b5563" }}>
              Last Updated at: {lastSaved}
            </span>
            <IconButton
              aria-label="close"
              onClick={handleCloseTextDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Summary"
              value={editedSummary}
              onChange={(e) => setEditedSummary(e.target.value)}
              multiline
              rows={12}
              fullWidth
              inputProps={{ style: { fontSize: 22, lineHeight: "28px" } }}
              sx={{ marginTop: "5px" }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Box display="flex" justifyContent="flex-start" gap="8px" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{ bgcolor: "#003cc5" }}
                >
                  Fetch Summary
                </Button>
              </Box> */}
              <Box display="flex" justifyContent="flex-end" gap="8px" mt={2}>
                <Button onClick={handleCloseTextDialog} sx={{ color: "#003cc5" }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{ bgcolor: "#003cc5" }}
                >
                  Save
                </Button>
              </Box>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  export default InspectionSummary;