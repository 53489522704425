import React, { useEffect } from 'react';
import './hero.css';
import { useState } from 'react';
import { Button } from '@mui/material';
import audi from '../../assets/images/hero/audi.png';
import ford from '../../assets/images/hero/ford.png';
import honda from '../../assets/images/hero/honda.png';
import hyundai from '../../assets/images/hero/hyundai.png';
import kia from '../../assets/images/hero/kia.png';
import mahindra from '../../assets/images/hero/mahindra.png';
import merc from '../../assets/images/hero/merc.png';
import mg from '../../assets/images/hero/mg.png';
import nissan from '../../assets/images/hero/nissan.png';
import skoda from '../../assets/images/hero/skoda.png';
import suzuki from '../../assets/images/hero/suzuki.png';
import tata from '../../assets/images/hero/tata.png';
import toyota from '../../assets/images/hero/toyota.png';
import volkswagen from '../../assets/images/hero/Volkswagen.png';
import volvo from '../../assets/images/hero/volvo.png';
import EngineIcon from '../../assets/images/hero/HeroRightImages/engineIcon.png';
import CarLoanIcon from '../../assets/images/hero/HeroRightImages/carLoanIcon.png';
import CarPaintIcon from '../../assets/images/hero/HeroRightImages/carPaintIcon.png';
import FairPriceIcon from '../../assets/images/hero/HeroRightImages/fairPriceIcon.png';
import ScannerIcon from '../../assets/images/hero/HeroRightImages/scannerIcon.png';
import ReactPlayer from 'react-player';

// import HeroImageNew from "./heroImaheNew.png";
import Slider from 'react-slick';
// import HeroRightImg from "../../assets/images/hero/heroright.png";
import HeroRightImg from '../../assets/images/hero/HeroImg.png';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 25000,
  slidesToShow: 5,
  slidesToScroll: 6,
  autoplaySpeed: 0,
  cssEase: 'linear',
  autoplay: true,
  variableWidth: true,
  infiniteScroll: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Hero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="hero-left-section">
          <div>
            <h2>
              Our Car Inspection Gaurantees{' '}
              <span style={{ color: '#ffeb5b' }}>Good used Car</span> at a
              <span style={{ color: '#ffeb5b' }}> Fair Price </span>
            </h2>
            <p style={{ fontFamily: 'matter', fontSize: '22px' }}>
              Get your car inspected anywhere in your city and get{' '}
              <strong>
                Extended Warranty
                <sup
                  style={{
                    paddingLeft: '5px',
                    verticalAlign: 'text-top',
                  }}
                >
                  *
                </sup>
              </strong>{' '}
              upto <strong>2 years</strong>
            </p>
          </div>
          <div className="heroImg-mobile">
            <ReactPlayer
              url="/wisedrivehero.mp4"
              width="90%"
              height="auto"
              style={{ margin: '10px' }}
              controls
            />
            <div className="hero-cards-container">
              <div className="hero-card">
                <h4 style={{ color: 'white' }}>200+</h4>
                <p>Check points covered</p>
              </div>
              <div className="hero-card">
                <img src={EngineIcon} alt="" />
                <p>Engine & Gear Box</p>
              </div>
              <div className="hero-card">
                <img src={FairPriceIcon} alt="" />
                <p>Fair Price Estimate</p>
              </div>
              <div className="hero-card">
                <img src={ScannerIcon} alt="" />
                <p>Advanced OBD2 Scanner</p>
              </div>
              <div className="hero-card">
                <img src={CarLoanIcon} alt="" />
                <p>Finance and RTO Check</p>
              </div>
              <div className="hero-card">
                <img src={CarPaintIcon} alt="" />
                <p>Dent and Paint Check</p>
              </div>
            </div>
          </div>
          <div className="left-btn-container">
            <a href="#inspection-plans">
              <Button
                sx={{ fontFamily: 'matterMedium' }}
                startIcon={<FeaturedPlayListOutlinedIcon />}
                className="hero-button-green"
                variant="outlined"
              >
                Book Inspection
              </Button>
            </a>
            <a href="#how-it-works">
              <Button
                sx={{ fontFamily: 'matterMedium' }}
                className="hero-button-white"
              >
                How it works
              </Button>
            </a>
          </div>
        </div>
        <div className="hero-right-section">
          <ReactPlayer
            url="/wisedrivehero.mp4"
            width="100%"
            height="400px"
            controls
            style={{ height: 'auto !important' }}
          />
        </div>
      </div>
      <div className="container hero-cards-container desk-top-view">
        <div className="hero-card">
          <h5 style={{ color: 'white', marginBottom: '15px' }}>200+</h5>
          <p>Check points covered</p>
        </div>
        <div className="hero-card">
          <img src={EngineIcon} alt="" />
          <p>Engine & Gear Box</p>
        </div>
        <div className="hero-card">
          <img src={FairPriceIcon} alt="" />
          <p>Fair Price Estimate</p>
        </div>
        <div className="hero-card">
          <img src={ScannerIcon} alt="" />
          <p>Advanced OBD2 Scanner</p>
        </div>
        <div className="hero-card">
          <img src={CarLoanIcon} alt="" />
          <p>Finance and RTO Check</p>
        </div>
        <div className="hero-card">
          <img src={CarPaintIcon} alt="" />
          <p>Dent and Paint Check</p>
        </div>
      </div>
      <div
        className="container hero-note-container "
        style={{ display: 'block' }}
      >
        <p className="hero-note">We provide inspection for all brands</p>

        <div className="hero-logos">
          <img src={suzuki} />
          <img src={hyundai} />
          <img src={honda} />
          <img src={toyota} />
          <img src={skoda} />
          <img src={tata} />
          <img src={ford} />
          <img src={kia} />
          <img src={mahindra} />
          <img src={volkswagen} />
          <img src={volvo} />
          <img src={merc} />
          <img src={audi} />
          <img src={mg} />
        </div>
      </div>

      <div className="userBannerSlicker">
        <Slider {...settings}>
          <img src={suzuki} width="20px" />
          <img src={hyundai} width="20px" />
          <img src={honda} width="20px" />
          <img src={toyota} width="20px" />
          <img src={skoda} width="20px" />
          <img src={tata} width="20px" />
          <img src={ford} width="20px" />
          <img src={kia} width="20px" />
          <img src={mahindra} />
          <img src={volkswagen} />
          <img src={volvo} />
          <img src={merc} />
          <img src={audi} />
          <img src={mg} />
        </Slider>
      </div>
    </section>
  );
}

export default Hero;
