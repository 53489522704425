import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { setItem } from "../../../../httpHelpers/storageHelper";
import { Close, Edit } from "@mui/icons-material";
import EditSummary from "./EditSummary";
import { PortalContext } from "../../../../context/carPortalContext";

const SummaryCard = ({ summary, isPublished }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { summaryData, updateSummaryData } = useContext(PortalContext);

    useEffect(() => {
      // This check ensures that we don't update the context if the incoming summary data is the same as what's already there.
      if (summary) {
          updateSummaryData({
              marketValue: summary.marketValue || summary?.aiInsights?.marketValue,
              recommended: summary.recommendedToBuy || summary?.aiInsights?.recommendedToBuy,
              overallRating: summary.overallRating || summary?.aiInsights?.overAllRating
          });
      }
  }, [summary]);
  
    const handleOpenEdit = () => setIsEditing(true);
    const handleCloseEdit = () => setIsEditing(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleDataChange = (newData) => {
      console.log(newData, 'New Data received');
      updateSummaryData(newData);  // Update data through context
  };

    
    return (
      <div className="summary-card-container">
        {/* Blue Background Container */}
        <div className="summary-card__background">
          {/* Summary Card */}
          <div className="summary-card">
            <div className="summary-card__top-bulge"></div>
            <div className="summary-card__content">
              {/* Customer Information */}
              <h2
                className="summary-card__customer-name"
                style={{ fontWeight: 700 }}
              >
                {summary?.customerName ? summary?.customerName : "-"}
              </h2>
              <p className="summary-card__customer-info">
                {summary?.inspectionDetails?.inspectionDate ? summary?.inspectionDetails?.inspectionDate : '-' } | {summary?.inspectionDetails?.location ? summary?.inspectionDetails?.location : '-'}
              </p>
  
              <p className="summary-card__customer-contact">
                {(summary?.carRegistrationNumber ? summary?.carRegistrationNumber : '-').toUpperCase()}
                {/* <button
                  className="summary-card__full-details"
                  onClick={handleOpenModal}
                >
                  <Launch fontSize="small" />
                </button> */}
              </p>
  
              {/* Divider */}
              <div className="summary-card__divider"></div>
  
              {/* Report Details */}
              <div className="summary-card__details">
                <div className="detail-row-report">
                  <div className="detail-row">
                    <span className="detail-label">Report for</span>
                    <span className="detail-value">{summary?.inspectionDetails?.packageName ? summary?.inspectionDetails?.packageName : '-'}</span>
                  </div>
  
                  <div
                    className="detail-row side-by-side"
                    onClick={handleOpenDialog}
                    style={{ cursor: "pointer", color: "#003cc5" }}
                  >
                    <span className="detail-label">Inspected by</span>
                    <span className="detail-value" style={{ color: "#003cc5" }}>
                      Wisedrive
                    </span>
                  </div>
                </div>
                <div className="detail-row-market full-width">
                  <span className="detail-label">Market Value</span>
                  <span
                    className="detail-value badge-highlight"
                    style={{ fontWeight: 800, fontSize: "16px" }}
                  >
                    {summaryData?.marketValue ?? '-'}
                  </span>
                </div>
                <div className="detail-row-overall">
                  <div className="detail-row">
                    <span className="detail-label">Overall Rating</span>
                    <span className="detail-value overall-rating">
                      <strong>{summaryData?.overallRating ?? '-'}</strong> / 10
                    </span>
                  </div>
  
                  <div className="detail-row">
                    <span className="detail-label">Recommended to buy</span>
                    <span className="detail-value badge-no" style={{ backgroundColor:
                          summaryData?.recommended === "Yes" ? "#ccf0e1" : "#fee2e2", color: summaryData?.recommended === "Yes" ? "#065f46" : "#b91c1c"}}>
                      {summaryData?.recommended ?? '-'}
                    </span>
                  </div>
                </div>
              </div>
              {!isPublished && <IconButton
                onClick={handleOpenEdit}
                style={{ position: "absolute", top: 16, right: 16 }}
              >
                <Edit />
              </IconButton>}
            </div>
            <Dialog
              open={isEditing}
              onClose={handleCloseEdit}
              fullWidth
              maxWidth="sm"
              PaperProps={{
                sx: {
                  borderRadius: "20px", // Add rounded corners
                },
              }}
            >
              <DialogTitle>Edit Summary Card</DialogTitle>
              <DialogContent sx={{ marginTop: "4px" }}>
                <EditSummary
                  onClose={handleCloseEdit}
                  data={summaryData}
                  onDataChange={handleDataChange}
                />
              </DialogContent>
            </Dialog>
          </div>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="xs"
            PaperProps={{
              sx: {
                borderRadius: "16px",
              },
            }}
          >
            <DialogTitle sx={{ color: "#003cc5" }}>
              Inspection Details
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: "30px" }}>
              <Grid container spacing={2}>
                {/* Row 1 */}
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: "#4b5563",
                      fontSize: "0.9rem",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Mechanic Name:
                  </Typography>
                  <Box display="flex" alignItems="end">
                    <Typography
                      variant="body1"
                      sx={{
                        paddingTop: "4px !important",
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: 700,
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      {summary?.inspectionDetails?.mechanicName ? summary?.inspectionDetails?.mechanicName : '-'}
                    </Typography>
                    {/* <Check sx={{ color: "green", marginLeft: "8px" }} /> */}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: "#4b5563",
                      fontSize: "0.9rem",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Inspection Date:
                  </Typography>
                  <Box display="flex" alignItems="end">
                    <Typography
                      variant="body1"
                      sx={{
                        paddingTop: "4px !important",
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: 700,
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      {summary?.inspectionDetails?.inspectionDate ? summary?.inspectionDetails?.inspectionDate : '-'}
                    </Typography>
                    {/* <Check sx={{ color: "green", marginLeft: "8px" }} /> */}
                  </Box>
                </Grid>
  
                {/* Row 2 */}
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: "#4b5563",
                      fontSize: "0.9rem",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Requested Date:
                  </Typography>
                  <Box display="flex" alignItems="end">
                    <Typography
                      variant="body1"
                      sx={{
                        paddingTop: "4px !important",
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: 700,
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      {summary?.inspectionDetails?.inspectionRequestedDate ? summary?.inspectionDetails?.inspectionRequestedDate : '-'}
                    </Typography>
                    {/* <Check sx={{ color: "green", marginLeft: "8px" }} /> */}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: "#4b5563",
                      fontSize: "0.9rem",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Location:
                  </Typography>
                  <Box display="flex" alignItems="end">
                    <Typography
                      variant="body1"
                      sx={{
                        paddingTop: "4px !important",
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: 700,
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      {summary?.inspectionDetails?.location ? summary?.inspectionDetails?.location : '-'}
                    </Typography>
                    {/* <Check sx={{ color: "green", marginLeft: "8px" }} /> */}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  };

  export default SummaryCard;